import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface gridFilterState {
	filterData: object;
}

const initialState: gridFilterState = {
	filterData: JSON.parse(localStorage.getItem('filterData') ?? '{}')
};

const slice = createSlice({
	name: 'gridFilter',
	initialState,
	reducers: {
		setGridFilter(state, action: PayloadAction<object>) {
			state.filterData = action.payload;
		}
	}
});

export default slice.reducer;
export const { setGridFilter } = slice.actions;
