import React from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AppComponent, ComponentDefinition } from 'utils/components';
import { sendHttpRequest } from 'utils/httpRequestManager';

export default function Button(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
}) {
	const variables = JSON.parse(props.component.variables);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	let componentClass = props.definition?.class;
	if ((componentClass == null || componentClass.startsWith('%')) && variables) {
		componentClass = variables.class;
	}
	let componentBody = props.definition?.component_body;
	if ((componentBody == null || componentBody.startsWith('%')) && variables) {
		componentBody = variables.caption;
	}

	function buttonClicked() {
		if (props.definition?.component_parameters) {
			const parameters = JSON.parse(props.definition.component_parameters);
			if (parameters.page_redirect) {
				const path = parameters.page_redirect.page;
				const pathParams = parameters.page_redirect.datasource_parameters as any;
				Object.entries(pathParams).forEach(([key, value]) => {
					if ((value as string).includes('%')) {
						pathParams[key as keyof typeof pathParams] = variables[
							(value as string).replaceAll('%', '')
						] as string;
					}
				});

				navigate({
					pathname: path,
					search: createSearchParams(pathParams).toString()
				});
			} else if (parameters.exec) {
				const data = {
					datasource_name: props.component.datasource_name,
					parameters: JSON.parse(props.component.datasource_parameters ?? '{}'),
					row_identifiers: props.component.row_identifiers ?? {},
					action: props.component.action ?? ''
				};

				sendHttpRequest('POST', '/grid/action', data, navigate, dispatch).then(() => {
					window.location.reload();
				});
			}
		}
	}

	return (
		<button className={componentClass ?? 'btn btn-link'} onClick={buttonClicked}>
			{componentBody}
		</button>
	);
}
