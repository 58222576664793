import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { DataSaveStatus, StockItem } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import PromotionCell from 'components/Forms/Promotion/PromotionCell';
import { Modal } from 'react-bootstrap';
import Input from 'components/Forms/FormComponents/Input';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import { StockItemOption, StockItemOptionCountry } from 'utils/promotion';
import { useTranslation } from 'react-i18next';
import { RootState } from 'state/store';

const sameItemCode = 'X999';

export default function PromotionStockItem(props: {
	template: string;
	productId: string;
	productIndex: number;
	i: number;
	item: StockItem;
	disabled: boolean;
	setValue: (
		item_id: number | null,
		tab_name: string | null,
		input_name: string,
		value: string,
		rounding?: number,
		country_index?: number,
		save?: boolean
	) => void;
	setDataSaveStatus?: (s: DataSaveStatus) => void;
	removeStockItem: (itemIndex: number) => void;
	calculateValues: () => void;
	countries: string[];
	itemsLength: number;
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [stockItemsDropdown, setStockItemsDropdown] = useState([] as StockItemOption[]);
	const [pickedStockItem, setPickedStockItem] = useState(null as StockItemOption | null);
	const [focusedStockItemOption, setFocusedStockItemOption] = useState(-1);
	const [isFocused, setIsFocused] = useState(false);
	const [showCodeModal, setShowCodeModal] = useState(false);
	const [pickerValue, setPickerValue] = useState(props.item.c_item);
	const [customStockItem, setCustomStockItem] = useState(false);

	const [newStockItemName, setNewStockItemName] = useState('');
	const [newStockItemSRP, setNewStockItemSRP] = useState('');
	const [newStockItemQtyInSet, setNewStockItemQtyInSet] = useState('');

	const allCountries = useSelector((state: RootState) => state.user.allCountries);
	const initialStockItemCountries = initStockItemsCountries();
	const [newStockItemCountries, setNewStockItemCountries] = useState([
		...initialStockItemCountries
	] as StockItemOptionCountry[]);

	const [countriesLinked, setCountriesLinked] = useState(false);

	const basePriceMultipliers = {
		AT: 0.34,
		DE: 0.369
	};

	const urlParams = useMemo(() => {
		return new URLSearchParams(window.location.search);
	}, []);

	const pickStockItems = useCallback(
		async (q: string, showDropdown = true) => {
			if (q.length >= 3) {
				const data = { query: q, i_list: parseInt(urlParams.get('i_list') ?? '1') };
				const response = await sendHttpRequest('POST', '/cartons', data, navigate, dispatch);
				const items = response.data[0].map((o: { countries: string }) => ({
					...o,
					countries: (JSON.parse(o.countries) as StockItemOptionCountry[]).map((c) => ({
						...c,
						retail_price: c.base_retail_price_current ?? c.base_retail_price_latest ?? 0,
						base_price: c.base_cost_price_current ?? c.base_cost_price_latest ?? 0
					}))
				})) as StockItemOption[];
				if (showDropdown) {
					setStockItemsDropdown(items);
				}
				return items;
			}
			return [];
		},
		[dispatch, navigate, urlParams]
	);

	function checkInputEqualsStockItem(value: string, si: StockItemOption[]) {
		const foundItem = si.find((item) => item.c_item === value);
		// onStockItemOptionPick(foundItem);
		return !!foundItem;
	}

	function onStockItemOptionPick(stockItem: StockItemOption | undefined) {
		setPickedStockItem(stockItem ?? null);
		if (stockItem) {
			setStockItemsDropdown([]);
			setPickerValue(stockItem.c_item);
			setNewStockItemName(stockItem.n_item);
			setNewStockItemSRP(parseFloat(stockItem.srp.toString()).toFixed(2));
			setNewStockItemQtyInSet(stockItem.qty_in_set.toString());
			setNewStockItemCountries(stockItem.countries);
		}
	}

	const stockItemOptionsRefs = useRef([] as HTMLLIElement[]);
	const stockItemCodeInputRef = useRef(null as HTMLInputElement | null);

	function onKeyDown(e: React.KeyboardEvent, stockItemTarget = null as StockItemOption | null) {
		if (stockItemTarget) {
			// the target is one of the list items
			e.preventDefault();
			if (e.key === 'Enter') {
				onStockItemOptionPick(stockItemTarget);
			}
		}

		let newFocusedItemOption = focusedStockItemOption;
		if (e.key === 'ArrowDown') {
			newFocusedItemOption = Math.min(stockItemsDropdown.length - 1, focusedStockItemOption + 1);
			stockItemOptionsRefs.current[newFocusedItemOption]?.focus();

			setFocusedStockItemOption(newFocusedItemOption);
		} else if (e.key === 'ArrowUp') {
			newFocusedItemOption = Math.max(-1, focusedStockItemOption - 1);
			if (newFocusedItemOption !== -1) {
				stockItemOptionsRefs.current[newFocusedItemOption].focus();
			} else {
				stockItemCodeInputRef.current?.focus();
			}

			setFocusedStockItemOption(newFocusedItemOption);
		} else {
			setFocusedStockItemOption(-1);
		}

		if (['Tab', 'Escape'].includes(e.key)) {
			setIsFocused(false);
			stockItemCodeInputRef.current?.focus();
		}
	}

	function setStockItem(stockItem?: StockItemOption) {
		props.setValue(props.i, null, 'c_item', pickerValue, undefined, undefined, false);
		props.setValue(props.i, null, 'n_item', newStockItemName, undefined, undefined, false);
		if (stockItem === undefined) {
			props.setValue(
				props.i,
				null,
				'qty_in_set',
				newStockItemQtyInSet,
				undefined,
				undefined,
				false
			);
		}
		set_n_item(newStockItemName);

		props.setValue(props.i, null, 'srp', newStockItemSRP, undefined, undefined, false);

		newStockItemCountries.forEach((c, ci) => {
			props.setValue(props.i, null, 'product_name', c.product_name ?? '', undefined, ci, false);
			props.setValue(props.i, null, 'retail_price', c.retail_price, undefined, ci, false);
			props.setValue(props.i, null, 'base_price', c.base_price, undefined, ci, false);
			const sic = stockItem?.countries.find((sc) => sc.i_country === c.i_country);
			props.setValue(
				props.i,
				null,
				'i_business',
				sic ? sic.i_sub_business.toString() : c.i_sub_business.toString(),
				undefined,
				ci,
				false
			);
		});
		props.setValue(
			props.i,
			null,
			'i_item',
			stockItem ? stockItem.i_item : pickedStockItem?.i_item ?? ''
		);

		setShowCodeModal(false);

		if (stockItem !== undefined) {
			setIsFocused(false);
		}
	}

	function isStockItemDropdownClicked(target: HTMLElement | null) {
		while (target) {
			if (target.classList.contains('stock-item-dropdown')) {
				return true;
			}
			target = target.parentElement;
		}
		return false;
	}

	function createStockItem() {
		const data = {
			product_code: pickerValue,
			product_name: newStockItemName,
			srp: parseFloat(newStockItemSRP),
			i_list: parseInt(urlParams.get('i_list') ?? '1')
		};
		sendHttpRequest('POST', '/stock-item', data, navigate, dispatch).then((response) => {
			const d = response.data[0][0];
			const si = {
				srp: d.SRP.toFixed(2),
				c_item: d.c_item,
				n_item: d.n_item,
				i_item: d.i_item,
				countries: JSON.parse(d.countries) as StockItemOptionCountry[]
			} as StockItemOption;
			setStockItem(si);
		});
	}

	function useHandleDocumentClick() {
		useEffect(() => {
			function handleClickOutside(event: MouseEvent) {
				const target = event.target as HTMLElement;
				if (!isStockItemDropdownClicked(target)) {
					setIsFocused(false);
				}
			}

			document.addEventListener('click', handleClickOutside);
			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}, []);
	}

	const base_price = useMemo(() => {
		if (props.countries.length === 1) {
			const c = props.item.countries.find((c) => c.i_country === props.countries[0]);
			if (typeof c?.base_price === 'number') {
				return c.base_price.toFixed(2);
			}
			return c?.base_price ?? '';
		} else {
			return '';
		}
	}, [props.countries, props.item]);

	const retail_price = useMemo(() => {
		if (props.countries.length === 1) {
			const c = props.item.countries.find((c) => c.i_country === props.countries[0]);
			if (typeof c?.retail_price === 'number') {
				return c.retail_price.toFixed(2);
			}
			return c?.retail_price ?? '';
		} else {
			return '';
		}
	}, [props.countries, props.item]);

	const tpag_price = useMemo(() => {
		if (props.countries.length === 1) {
			const c = props.item.countries.find((c) => c.i_country === props.countries[0]);
			if (typeof c?.tpag_price === 'number') {
				return c.tpag_price.toFixed(2);
			}
			return c?.tpag_price;
		} else {
			return '';
		}
	}, [props.countries, props.item]);

	const approved = useMemo(() => {
		if (props.countries.length === 1) {
			if (tpag_price === null) {
				return null;
			}
			return tpag_price === base_price;
		} else {
			return (
				props.item.countries.length &&
				props.item.countries.every((c) => {
					const tp = c.tpag_price?.toString();
					const bp = c.base_price?.toString();
					return tp === bp && tp !== undefined;
				})
			);
		}
	}, [props.countries, props.item, tpag_price, base_price]);

	const [n_item, set_n_item] = useState('');

	useEffect(() => {
		if (props.countries.length === 1 && pickerValue !== sameItemCode) {
			const c = props.item.countries.find((c) => c.i_country === props.countries[0]);
			set_n_item(c?.product_name ?? '');
		} else {
			set_n_item(props.item.n_item);
		}
	}, [props.countries, props.item]);

	useHandleDocumentClick();

	function setStockItemCountryValue(
		col_key: 'retail_price' | 'product_name' | 'base_price',
		i_country: string,
		value: string
	) {
		const countries = [...newStockItemCountries];
		const ic = countries.findIndex((c) => c.i_country == i_country);
		if (col_key == 'product_name') {
			countries[ic].product_name = value;
		} else if (col_key == 'retail_price') {
			countries[ic].retail_price = value;
			countries[ic].base_price = (
				parseFloat(value) * basePriceMultipliers[i_country as keyof typeof basePriceMultipliers]
			).toFixed(2);
		} else if (col_key == 'base_price') {
			countries[ic].base_price = value;
		}
		setNewStockItemCountries(countries);
	}

	function initStockItemsCountries() {
		const res = [] as StockItemOptionCountry[];

		allCountries.forEach((c) => {
			res.push({
				i_country: c,
				product_name: '',
				i_sub_business: 0,
				base_price: '',
				retail_price: '',
				base_retail_price_current: null,
				base_retail_price_latest: null,
				base_cost_price_current: null,
				base_cost_price_latest: null
			});
		});

		return res;
	}

	function stockItemEditable() {
		return (
			(customStockItem || pickedStockItem !== null) &&
			pickerValue.length >= 3 &&
			pickerValue !== sameItemCode
		);
	}

	const disableConfirmButton = useMemo(() => {
		let disable = [newStockItemName, newStockItemSRP, newStockItemQtyInSet].includes('');

		if (pickerValue === sameItemCode) {
			return disable;
		}
		newStockItemCountries.forEach((c) => {
			if (
				[c.product_name, c.base_price, c.retail_price].includes('') ||
				c.product_name === null ||
				[parseFloat(c.base_price), parseFloat(c.retail_price)].includes(0)
			) {
				disable = true;
			}
		});

		return disable;
	}, [newStockItemName, newStockItemSRP, newStockItemQtyInSet, newStockItemCountries, pickerValue]);

	function onCountriesLinkedChange() {
		const newVal = !countriesLinked;
		setCountriesLinked(newVal);

		if (newVal) {
			linkCountries();
		}
	}

	function linkCountries() {
		setNewStockItemCountries(
			newStockItemCountries.map((c) => {
				c.product_name = newStockItemCountries[0].product_name;
				c.retail_price = newStockItemCountries[0].retail_price;
				if (newStockItemCountries[0].retail_price !== '') {
					c.base_price = (
						parseFloat(newStockItemCountries[0].retail_price) *
						basePriceMultipliers[c.i_country as keyof typeof basePriceMultipliers]
					).toFixed(2);
				}
				return c;
			})
		);
	}

	useEffect(() => {
		if (newStockItemCountries.length > 0) {
			setCountriesLinked(
				newStockItemCountries.every(
					(nsic) =>
						nsic.product_name === newStockItemCountries[0].product_name &&
						nsic.retail_price === newStockItemCountries[0].retail_price
				)
			);
		}
	}, [newStockItemCountries]);

	useEffect(() => {
		if (showCodeModal && props.item.c_item !== '') {
			(async () => {
				const items = await pickStockItems(props.item.c_item, false);
				if (items[0]?.c_item === props.item.c_item) {
					onStockItemOptionPick(items[0]);
				}
			})();
		}
	}, [pickStockItems, props.item.c_item, showCodeModal]);

	return (
		<div className="mt-2 row">
			<div className="col-1 px-1">
				<PromotionCell
					template={props.template}
					productIndex={props.productIndex}
					productId={props.productId}
					name={'quantity'}
					type={'number'}
					min={1}
					required={true}
					disabled={props.disabled}
					defaultValue={props.item.quantity?.toString()}
					setValue={props.setValue}
					itemId={props.i}
					rounding={undefined}
					setDataSaveStatus={props.setDataSaveStatus}
				/>
			</div>
			<div className={'col-5 px-1 position-relative'}>
				<div className="input-group stock-item-group">
					<button
						className="form-control stock-item-code-trigger"
						type="button"
						disabled={props.disabled}
						onClick={() => {
							setShowCodeModal(true);
							setPickerValue(props.item.c_item);
							setNewStockItemName('');
							setNewStockItemSRP('');
							setNewStockItemCountries([...initialStockItemCountries] as StockItemOptionCountry[]);
							setNewStockItemQtyInSet('');
							setCustomStockItem(false);
						}}>
						{props.item.c_item}
					</button>
					<Modal
						show={showCodeModal}
						size={'lg'}
						onShow={() => (stockItemCodeInputRef.current as HTMLInputElement).focus()}
						onHide={() => setShowCodeModal(false)}>
						<Modal.Header closeButton></Modal.Header>

						<Modal.Body>
							<div className="row mx-0">
								<div className="col-3 ps-0 pe-1">
									<div className="form-floating">
										<input
											id={props.template + '.' + props.productIndex + '.' + props.i + '.c_item'}
											className="form-control"
											ref={stockItemCodeInputRef}
											name={props.template + '.' + props.productIndex + '.' + props.i + '.c_item'}
											type="text"
											required={true}
											value={pickerValue}
											disabled={props.disabled}
											autoComplete="off"
											onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
												const newValue = e.target.value;
												setPickerValue(newValue.toUpperCase());
												setStockItemsDropdown([]);
												onStockItemOptionPick(undefined);
												setNewStockItemName('');
												setNewStockItemSRP('');
												setNewStockItemQtyInSet('');
												setNewStockItemCountries([...initialStockItemCountries]);
												setCustomStockItem(false);
												const si = await pickStockItems(newValue);
												if (!checkInputEqualsStockItem(newValue, si)) {
													setIsFocused(true);
													if (si.length == 0 && newValue.toLowerCase().startsWith('np')) {
														setCustomStockItem(true);
														setNewStockItemSRP('0.00');
														setNewStockItemQtyInSet('1');
													} else {
														setCustomStockItem(false);
													}
												}
											}}
											onKeyDown={(e) => onKeyDown(e)}
										/>
										<label>{t('promotion.fields.stock_item')}</label>
									</div>
								</div>
								<div className="col-5 px-1">
									<Input
										name={props.template + '.' + props.productIndex + '.' + props.i + '.n_item'}
										type="text"
										defaultValue={newStockItemName}
										disabled={
											!customStockItem || pickedStockItem !== null || pickerValue.length < 3
										}
										required={true}
										label={t('promotion.fields.stock_item_name')}
										onChange={(e) => setNewStockItemName(e.target.value)}
										customClass={
											!(!customStockItem || pickedStockItem !== null || pickerValue.length < 3) &&
											newStockItemName === ''
												? 'border-danger'
												: ''
										}
									/>
								</div>
								<div className="col-2 px-1">
									<Input
										name={props.template + '.' + props.productIndex + '.' + props.i + '.srp'}
										type="number"
										rounding={2}
										customClass={
											'text-end' +
											(!(!customStockItem || pickedStockItem !== null || pickerValue.length < 3) &&
											newStockItemSRP === ''
												? ' border-danger'
												: '')
										}
										defaultValue={newStockItemSRP}
										disabled={
											!customStockItem || pickedStockItem !== null || pickerValue.length < 3
										}
										required={true}
										label={t('promotion.fields.srp')}
										onChange={(e) => setNewStockItemSRP(e.target.value)}
									/>
								</div>
								<div className="col-2 ps-1 pe-0">
									<Input
										name={props.template + '.' + props.productIndex + '.' + props.i + '.qty_in_set'}
										type="number"
										customClass={
											'text-end' +
											(customStockItem && newStockItemQtyInSet === '' ? ' border-danger' : '')
										}
										defaultValue={newStockItemQtyInSet}
										disabled={!customStockItem}
										required={true}
										label={t('promotion.fields.qty_in_set')}
										onChange={(e) => setNewStockItemQtyInSet(e.target.value)}
									/>
								</div>
							</div>
							{newStockItemCountries.length > 0 && (
								<div className="row mt-2 mx-0 stock-item-header-labels">
									<div className="col-1"></div>
									<div className="col-7 px-1">
										<div className="px-3">{t('promotion.fields.product_name')}</div>
									</div>
									<div className="col-2 px-1">
										<div className="px-3">{t('promotion.fields.retail_price')}</div>
									</div>
									<div className="col-2 ps-1 pe-0">
										<div className="px-3">{t('promotion.fields.base_price')}</div>
									</div>
								</div>
							)}
							{newStockItemCountries.map((siCountry, i) => (
								<div
									key={siCountry.i_country}
									className="stock-item-form-country row mt-2 mx-0 align-items-center">
									<div className="col-1 px-1 d-flex justify-content-end position-relative">
										{stockItemEditable() && i === 0 && newStockItemCountries.length > 1 && (
											<button
												type="button"
												tabIndex={-1}
												className="btn names-linked-toggle px-2"
												onClick={onCountriesLinkedChange}>
												<i
													className={
														'fs-5 bi bi-link-45deg ' +
														(countriesLinked ? 'color-primary-shade' : 'color-gray')
													}
												/>
											</button>
										)}
										<div className="country-label w-60">{siCountry.i_country}</div>
									</div>
									<div className="col-7 px-1">
										<Input
											name={
												props.template +
												'.' +
												props.productIndex +
												'.' +
												props.i +
												'.' +
												siCountry.i_country +
												'.product_name'
											}
											type="text"
											defaultValue={siCountry.product_name ?? ''}
											disabled={!stockItemEditable() || (countriesLinked && i !== 0)}
											required={true}
											onChange={(e) => {
												setStockItemCountryValue(
													'product_name',
													siCountry.i_country,
													e.target.value
												);

												if (countriesLinked) {
													linkCountries();
												}
											}}
											customClass={
												stockItemEditable() &&
												!(countriesLinked && i !== 0) &&
												['', null].includes(siCountry.product_name)
													? 'border-danger'
													: ''
											}
										/>
									</div>
									<div className="col-2 px-1">
										<Input
											name={
												props.template +
												'.' +
												props.productIndex +
												'.' +
												props.i +
												'.' +
												siCountry.i_country +
												'.retail_price'
											}
											type="number"
											defaultValue={siCountry.retail_price}
											disabled={!stockItemEditable()}
											rounding={2}
											required={true}
											customClass={
												'text-end' +
												(stockItemEditable() && ['', '0.00'].includes(siCountry.retail_price)
													? ' border-danger'
													: '')
											}
											onChange={(e) => {
												setStockItemCountryValue(
													'retail_price',
													siCountry.i_country,
													e.target.value
												);
											}}
										/>
									</div>
									<div className="col-2 ps-1 pe-0">
										<Input
											name={
												props.template +
												'.' +
												props.productIndex +
												'.' +
												props.i +
												'.' +
												siCountry.i_country +
												'.base_price'
											}
											type="number"
											rounding={2}
											disabled={pickedStockItem == null || pickerValue !== sameItemCode}
											customClass="text-end"
											defaultValue={siCountry.base_price}
											required={true}
											onChange={(e) => {
												setStockItemCountryValue('base_price', siCountry.i_country, e.target.value);
											}}
										/>
									</div>
								</div>
							))}
							<div className="col-12">
								<button
									type="button"
									className="btn btn-primary mt-3 col-12 btn-lg"
									onClick={() => {
										if (customStockItem && pickerValue.length > 3) {
											createStockItem();
										} else if (pickedStockItem !== null && pickerValue.length > 3) {
											setStockItem();
										}
									}}
									disabled={disableConfirmButton}>
									{customStockItem && pickerValue.length > 3
										? t('promotion.fields.create_custom_stock_item')
										: t('promotion.fields.choose_stock_item')}
								</button>
							</div>

							{isFocused && stockItemsDropdown.length > 0 && (
								<ul className="stock-item-dropdown w-60">
									{stockItemsDropdown.map((stockItem, j) => (
										<li
											tabIndex={0}
											ref={(el) => (stockItemOptionsRefs.current[j] = el as HTMLLIElement)}
											className="stock-item-dropdown-content font-small py-1 px-2 d-flex"
											onClick={() => onStockItemOptionPick(stockItem)}
											onKeyDown={(e) => onKeyDown(e, stockItem)}
											key={j}>
											<span className="col-3 font-small">
												<b>{stockItem.c_item}</b>
											</span>
											<span className="col-7 font-small">{stockItem.n_item}</span>
											<span className="col-2 font-small text-end">
												<b>{stockItem.srp && parseFloat(stockItem.srp.toString()).toFixed(2)}</b>
											</span>
										</li>
									))}
								</ul>
							)}
						</Modal.Body>
					</Modal>
					{props.countries.length === 1 ? (
						<PromotionCell
							template={props.template}
							productIndex={props.productIndex}
							productId={props.productId}
							name={'product_name'}
							disabled={true}
							type={'text'}
							maxLength={30}
							required={true}
							setValue={props.setValue}
							itemId={props.i}
							country={props.countries[0]}
							defaultValue={n_item}
						/>
					) : (
						<PromotionCell
							template={props.template}
							productIndex={props.productIndex}
							productId={props.productId}
							name={'n_item'}
							type={'text'}
							maxLength={30}
							required={true}
							setValue={props.setValue}
							itemId={props.i}
							defaultValue={n_item}
							disabled={true}
							tooltip={true}
						/>
					)}
				</div>
			</div>
			<div className="col-2 px-1">
				<PromotionCell
					template={props.template}
					productIndex={props.productIndex}
					productId={props.productId}
					name="srp"
					type="number"
					customClass="text-end"
					required={true}
					disabled={true}
					defaultValue={props.item.srp?.toString()}
					setValue={props.setValue}
					itemId={props.i}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						e.preventDefault();
					}}
					min={0}
					step={0.01}
				/>
			</div>
			<div className="col-3 d-flex align-items-center justify-content-center">
				<div className="row">
					{props.countries.length === 1 ? (
						<>
							<div className="col-6 px-1">
								<PromotionCell
									template={props.template}
									productIndex={props.productIndex}
									productId={props.productId}
									name="retail_price"
									type="number"
									customClass="text-end"
									required={true}
									defaultValue={retail_price}
									country={props.countries[0]}
									setValue={props.setValue}
									rounding={2}
									disabled={true}
									itemId={props.i}
									min={0}
									step={0.01}
									setDataSaveStatus={props.setDataSaveStatus}
								/>
							</div>
							<div className="col-6 px-1">
								<PromotionCell
									template={props.template}
									productIndex={props.productIndex}
									productId={props.productId}
									name="base_price"
									type="number"
									customClass={
										'text-end ' +
										(approved
											? 'border-success bg-green-tint'
											: approved === false
												? 'border-warning bg-yellow-tint'
												: 'border-danger bg-red-tint')
									}
									required={true}
									defaultValue={base_price}
									country={props.countries[0]}
									setValue={props.setValue}
									disabled={true}
									itemId={props.i}
								/>
							</div>
						</>
					) : (
						<div className="col-12 px-1">
							<div
								className={
									'approval-status fw-medium ' + (approved ? 'color-green-dark' : 'color-red-dark')
								}>
								{approved ? t('promotion.fields.approved') : t('promotion.fields.not_approved')}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="col-1 d-flex pe-0 align-items-center justify-content-end">
				{!props.disabled && (props.itemsLength > 1 || props.i !== 0) && (
					<button
						type="button"
						className="btn btn-icon color-gray"
						onClick={() => props.removeStockItem(props.i)}>
						<i className="bi bi-x-lg" />
					</button>
				)}
			</div>
		</div>
	);
}
